// Framework
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
// Classes
import { BaseUser } from "@src/app/modules/share/classes/base-user.class";
// Enums
import { Gender } from "@src/app/modules/share/enums/gender.enum";
// Global
import { backendURL } from "@src/app/global";
// Services
import { DataApiService } from "@src/app/modules/share/services/templates/data-api.service";

@Injectable({
    providedIn: "root"
})
export class ProfileApiService extends DataApiService<BaseUser> {
    private dummyData = new BaseUser({
        usaagoForename: "Test",
        usaagoSurname: "User",
        usaagoEmail: "t@t.de",
        usaagoGender: Gender.female,
        usaagoIsPlatformAdmin: true,
    });
    private requestOptionsFileUpload: object;

    constructor(protected http: HttpClient) {
        super(backendURL + "profile/", http, BaseUser);
        this.requestOptionsFileUpload = { ...this.requestOptions, headers: null };
    }

    postResetPassword(emailValue: string): Observable<void> {
        return this.http.post<void>(this.url + "password-reset", { usaagoEmail: emailValue }, this.requestOptions);
        // return of(null);
    }

    postPasswordChange(passwordValue: string, token: string): Observable<void> {
        return this.http.patch<void>(this.url + "password-reset/" + token, { password: passwordValue }, this.requestOptions);
        // return of(null);
    }

    getUser(id: string): Observable<BaseUser> {
        return this.getOne(id);
        // return of(this.dummyData);
    }

    getProfile(): Observable<BaseUser> {
        return this.getOne("");
        // return of(this.dummyData);
    }

    updateProfile(user: BaseUser): Observable<BaseUser> {
        return this.put("", user);
        // return of(user);
    }

    changePassword(passwordValue: string, newPasswordValue: string): Observable<void> {
        return this.http.patch<void>(this.url + "password/", { usaagoOldPassword: passwordValue, usaagoNewPassword: newPasswordValue }, this.requestOptions);
        // return of(null);
    }

    uploadProfileImage(profileImage: File): Observable<any> {
        const formData = new FormData();
        formData.append("profileImage", profileImage);

        return this.http.patch<any>(this.url + "image", formData, this.requestOptionsFileUpload);
        // return of(null);
    }

    verifyEmail(tokenValue: string): Observable<void> {
        return this.http.patch<void>(this.url + "email-verification/", { token: tokenValue }, this.requestOptions);
        // return of(null);
    }

    removeProfileImage(): Observable<void> {
        return this.deleteOne("image");
        // return of(null);
    }

    patchPreferredLanguage(language: string): Observable<void> {
        return this.http.patch<void>(this.url + "preferred-language/", { usaagoPreferredLanguage: language }, this.requestOptions);
        // return of(null);
    }
}
