// Framework
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Enums
import { TransactionStatus } from "../enums/transaction-status.enum";

@Pipe({
    name: "transactionStatus",
})
export class TransactionStatusPipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: TransactionStatus): string {
        switch (value) {
            case TransactionStatus.approved:
                return this.translate.instant("transaction-status-pipe.approved");
            case TransactionStatus.canceled:
                return this.translate.instant("transaction-status-pipe.canceled");
            case TransactionStatus.failed:
                return this.translate.instant("transaction-status-pipe.failed");
            case TransactionStatus.open:
                return this.translate.instant("transaction-status-pipe.open");
            case TransactionStatus.paid:
                return this.translate.instant("transaction-status-pipe.paid");
            default:
                return null;
        }
    }
}
