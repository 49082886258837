// Classes
import { Feature } from "./feature.class";
import { GroupPermission } from "./group-permission.class";
import { ImplicitGroupPermission } from "./implicit-group-permission.class";
import { OwnPermission } from "./own-permission.class";
import { UserPermission } from "./user-permission.class";

export class FeatureGroup {
    id: string = null;
    usaagoGroupName: string = null;
    usaagoFeatures: Feature[] = [];
    usaagoUserPermissions: UserPermission[] = [];
    usaagoGroupPermission: GroupPermission[] = [];
    usaagoImplicitGroupPermissions: ImplicitGroupPermission[] = [];
    usaagoOwnPermissions: OwnPermission[] = [];

    constructor(featureGroupShape?: Partial<FeatureGroup>) {
        if (featureGroupShape != null) {
            if (featureGroupShape.id != null) {
                this.id = featureGroupShape.id;
            }

            if (featureGroupShape.usaagoGroupName != null) {
                this.usaagoGroupName = featureGroupShape.usaagoGroupName;
            }

            if (featureGroupShape.usaagoFeatures != null) {
                this.usaagoFeatures = featureGroupShape.usaagoFeatures.map((value) => new Feature(value));
            }

            if (featureGroupShape.usaagoUserPermissions != null) {
                this.usaagoUserPermissions = featureGroupShape.usaagoUserPermissions.map((value) => new UserPermission(value));
            }

            if (featureGroupShape.usaagoGroupPermission != null) {
                this.usaagoGroupPermission = featureGroupShape.usaagoGroupPermission.map((value) => new GroupPermission(value));
            }

            if (featureGroupShape.usaagoImplicitGroupPermissions != null) {
                this.usaagoImplicitGroupPermissions = featureGroupShape.usaagoImplicitGroupPermissions.map((value) => new ImplicitGroupPermission(value));
            }

            if (featureGroupShape.usaagoOwnPermissions != null) {
                this.usaagoOwnPermissions = featureGroupShape.usaagoOwnPermissions.map((value) => new OwnPermission(value));
            }
        }
    }
}
