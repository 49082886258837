// Classes
import { BaseUser } from "./base-user.class";

export class ImplicitGroup {
    id: string = null;
    usaagoPosition: number = null;
    usaagoImplicitGroupName: string = null;
    usaagoUsers: BaseUser[] = null;

    constructor(groupShape?: Partial<ImplicitGroup>) {
        if (groupShape != null) {
            if (groupShape.id != null) {
                this.id = groupShape.id;
            }

            if (groupShape.usaagoPosition != null) {
                this.usaagoPosition = groupShape.usaagoPosition;
            }

            if (groupShape.usaagoImplicitGroupName != null) {
                this.usaagoImplicitGroupName = groupShape.usaagoImplicitGroupName;
            }

            if (groupShape.usaagoUsers != null) {
                this.usaagoUsers = groupShape.usaagoUsers.map((value) => new BaseUser(value));
            }
        }
    }
}
