// Framework
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Enums
import { ImageType } from "../enums/image-type.enum";

@Pipe({
    name: "imageType"
})
export class ImageTypePipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: ImageType): string {
        switch (value) {
            case ImageType.main:
                return this.translate.instant("image-type-pipe.main");
            case ImageType.thumbnail:
                return this.translate.instant("image-type-pipe.thumbnail");
            case ImageType.other:
                return this.translate.instant("image-type-pipe.other");
            case ImageType.tastingNote:
                return this.translate.instant("image-type-pipe.tasting-note");
            default:
                return "";
        }
    }
}
