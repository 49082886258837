// Framework
import { Component, OnInit } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { iif, of, Subscription } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
// Services
import { CookieConsentBannerService } from "./modules/cookie/services/cookie-consent-banner.service";
import { ProfileApiService } from "./modules/profile-editor/services/api/profile-api.service";
import { AppSettingsService } from "./modules/share/services/app-settings.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    private subscription = new Subscription();
    title = "usaago-frontend-ng";
    isLoggedIn = false;

    constructor(
        private cookieConsentBannerService: CookieConsentBannerService,
        private appSettings: AppSettingsService,
        private translateService: TranslateService,
        private profileApiService: ProfileApiService
    ) { }

    ngOnInit(): void {
        this.cookieConsentBannerService.initBanner();
        this.setCurrentLanguageOfAppSettings();
        this.registerIsLoggedInSubscription();
        this.registerOnLanguageChangeSubscription();
    }

    /**
     * This method checks if there is a cookie for the current user and the selected language and then sets it.
     */
    private setCurrentLanguageOfAppSettings(): void {
        if (this.appSettings.getCurrentUser().usaagoPreferredLanguage != null) {
            this.translateService.use(this.appSettings.getCurrentUser().usaagoPreferredLanguage);
        }
    }

    /**
     * This method registers a subscription for the current login status of the app and sets it in the isLoggedIn variable.
     */
    private registerIsLoggedInSubscription(): void {
        this.subscription.add(this.appSettings.getIsLoggedInAsObservable()
            .subscribe(
                (value) => this.isLoggedIn = value,
                (error) => console.error(error)
            )
        );
    }

    /**
     * This method registers a subscription for the language change.
     * Every time a change has taken place, the preference is stored in the cookie and in the backend (if logged in).
     */
    private registerOnLanguageChangeSubscription(): void {
        this.subscription.add(this.translateService.onLangChange
            .pipe(
                mergeMap((event: LangChangeEvent) => {
                    // Update the cookies
                    const tmpUser = this.appSettings.getCurrentUser();
                    tmpUser.usaagoPreferredLanguage = event.lang;
                    this.appSettings.saveCurrentUser(tmpUser);

                    return iif(
                        () => this.isLoggedIn === true,
                        this.profileApiService.patchPreferredLanguage(event.lang), // Update the preferred language for the user in the backend
                        of(null)
                    );
                }),
                catchError((err) => {
                    console.error(err);
                    return of(null);
                }),
            )
            .subscribe()
        );
    }
}
