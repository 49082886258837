// Framework
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
// Classes
import { Feature } from "../../classes/feature.class";
import { FeatureGroup } from "../../classes/feature-group.class";
import { OwnPermission } from "../../classes/own-permission.class";
// Global
import { backendURL } from "@src/app/global";
// Services
import { DataApiService } from "@src/app/modules/share/services/templates/data-api.service";

@Injectable({
    providedIn: "root"
})
export class FeatureGroupApiService extends DataApiService<FeatureGroup> {
    private dummyData = [
        new FeatureGroup({
            id: "fg1",
            usaagoOwnPermissions: [
                new OwnPermission({
                    usaagoFeature: new Feature({
                        id: "f1",
                        usaagoKey: "feature-key-1",
                    }),
                    usaagoIsAllowed: true,
                }),
                new OwnPermission({
                    usaagoFeature: new Feature({
                        id: "f2",
                        usaagoKey: "feature-key-2",
                    }),
                    usaagoIsAllowed: true,
                }),
            ],
        }),
        new FeatureGroup({
            id: "fg2",
            usaagoOwnPermissions: [
                new OwnPermission({
                    usaagoFeature: new Feature({
                        id: "f11",
                        usaagoKey: "feature-key-11",
                    }),
                    usaagoIsAllowed: true,
                }),
                new OwnPermission({
                    usaagoFeature: new Feature({
                        id: "f22",
                        usaagoKey: "feature-key-22",
                    }),
                    usaagoIsAllowed: true,
                }),
            ],
        }),
    ];

    constructor(protected http: HttpClient) {
        super(backendURL + "feature-groups/", http, FeatureGroup);
    }

    getOwnFeatureGroups(): Observable<FeatureGroup[]> {
        // return this.getAll("own");
        return of(this.dummyData);
    }
}
