// Framework
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Enums
import { OrderStatus } from "../enums/order-status.enum";

@Pipe({
    name: "orderStatus",
})
export class OrderStatusPipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: OrderStatus): string {
        switch (value) {
            case OrderStatus.pending:
                return this.translate.instant("order-status-pipe.pending");
            case OrderStatus.invoiced:
                return this.translate.instant("order-status-pipe.invoiced");
            case OrderStatus.paid:
                return this.translate.instant("order-status-pipe.paid");
            case OrderStatus.shipped:
                return this.translate.instant("order-status-pipe.shipped");
            default:
                return null;
        }
    }
}
