<div class="modal-header d-flex align-items-center">
    <h4 class="modal-title">{{ headerText }}</h4>
</div>
<div class="modal-body p-4">
    <div class="row">
        <div class="col-12">
            <p>{{ bodyText }}</p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="onCloseClicked()" class="btn btn-outlined">
        {{ cancelButtonLabel }}
    </button>
    <button type="button" (click)="onSubmitClicked()" class="btn btn-outlined">
        {{ confirmButtonLabel }}
    </button>
</div>
