interface BaseStorageData {
    storageVersion: string;
}

export abstract class BaseLocalStorageService<T extends BaseStorageData> {
    protected readonly storageName: string = null;
    protected readonly storageVersion: string = null;

    constructor(storageName: string, storageVersion: string) {
        this.storageName = storageName;
        this.storageVersion = storageVersion;
    }

    /**
     * This method loads the values from the storage and transform it into a generic shape. If there is no data available it will generate a default object of
     * the generic type.
     * @returns saved storage data or default object
     */
    loadStorageData(): T {
        let returnValue: T = this.getDefaultStorageData();

        if (localStorage.getItem(this.storageName) != null) {
            const data: T = JSON.parse(localStorage.getItem(this.storageName));

            if (data.storageVersion !== this.storageVersion) {
                this.clearStorageData();
            } else {
                returnValue = Object.assign(returnValue, data);
            }
        }

        return returnValue;
    }

    /**
     * This method saves the values into the storage. If there is an existing item it will be replaced.
     */
    saveStorageData(data: T): void {
        this.clearStorageData();
        localStorage.setItem(this.storageName, JSON.stringify(data));
    }

    /**
     * This method removes the storage.
     */
    clearStorageData(): void {
        localStorage.removeItem(this.storageName);
    }

    /**
     * This method returns a default initialized object of a generic type.
     * @returns default initialized object
     */
    protected abstract getDefaultStorageData(): T;
}
