// Classes
import { ImplicitGroup } from "./implicit-group.class";
import { Feature } from "./feature.class";

export class ImplicitGroupPermission {
    usaagoImplicitGroup: ImplicitGroup = null;
    usaagoFeature: Feature = null;
    usaagoIsAllowed = false;

    constructor(implicitGroupPermissionShape?: Partial<ImplicitGroupPermission>) {
        if (implicitGroupPermissionShape != null) {
            if (implicitGroupPermissionShape.usaagoImplicitGroup != null) {
                this.usaagoImplicitGroup = new ImplicitGroup(implicitGroupPermissionShape.usaagoImplicitGroup);
            }

            if (implicitGroupPermissionShape.usaagoFeature != null) {
                this.usaagoFeature = new Feature(implicitGroupPermissionShape.usaagoFeature);
            }

            if (implicitGroupPermissionShape.usaagoIsAllowed != null) {
                this.usaagoIsAllowed = implicitGroupPermissionShape.usaagoIsAllowed;
            }
        }
    }
}
