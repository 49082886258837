// Framework
import { Component, Input } from "@angular/core";
// Plugins
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-confirm-modal",
    templateUrl: "./confirm-modal.component.html",
    styleUrls: ["./confirm-modal.component.scss"]
})
export class ConfirmModalComponent {
    @Input() modal: NgbActiveModal;
    @Input() headerText: string = null;
    @Input() bodyText: string = null;
    @Input() cancelButtonLabel = this.translate.instant("confirm-modal.no");
    @Input() confirmButtonLabel = this.translate.instant("confirm-modal.yes");

    constructor(private translate: TranslateService) { }

    /**
     * When the close button is clicked, close the modal with the null value as parameter.
     */
    onCloseClicked(): void {
        this.modal.close(null);
    }

    /**
     * When the submit button is clicked, close the modal and send the true value as parameter.
     */
    onSubmitClicked(): void {
        this.modal.close(true);
    }
}
