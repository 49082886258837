// Framework
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Enums
import { OrganizationType } from "../enums/organization-type.enum";

@Pipe({
    name: "organizationType"
})
export class OrganizationTypePipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: OrganizationType): string {
        switch (value) {
            case OrganizationType.cafe:
                return this.translate.instant("organization-type-pipe.cafe");
            case OrganizationType.office:
                return this.translate.instant("organization-type-pipe.office");
            case OrganizationType.reseller:
                return this.translate.instant("organization-type-pipe.reseller");
            case OrganizationType.subscription:
                return this.translate.instant("organization-type-pipe.subscription");
            default:
                return null;
        }
    }
}
