// Classes
import { BaseUser } from "./base-user.class";
import { Feature } from "./feature.class";

export class UserPermission {
    usaagoUser: BaseUser = null;
    usaagoFeature: Feature = null;
    usaagoIsAllowed = false;

    constructor(userPermissionShape?: Partial<UserPermission>) {
        if (userPermissionShape != null) {
            if (userPermissionShape.usaagoUser != null) {
                this.usaagoUser = new BaseUser(userPermissionShape.usaagoUser);
            }

            if (userPermissionShape.usaagoFeature != null) {
                this.usaagoFeature = new Feature(userPermissionShape.usaagoFeature);
            }

            if (userPermissionShape.usaagoIsAllowed != null) {
                this.usaagoIsAllowed = userPermissionShape.usaagoIsAllowed;
            }
        }
    }
}
