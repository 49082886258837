export class Feature {
    id: string = null;
    usaagoKey: string = null;
    usaagoFeatureName: string = null;
    usaagoFeatureGroupId: string = null;
    usaagoImpliedFeatures: Feature[] = [];

    constructor(featureShape?: Partial<Feature>) {
        if (featureShape != null) {
            if (featureShape.id != null) {
                this.id = featureShape.id;
            }

            if (featureShape.usaagoKey != null) {
                this.usaagoKey = featureShape.usaagoKey;
            }

            if (featureShape.usaagoFeatureName != null) {
                this.usaagoFeatureName = featureShape.usaagoFeatureName;
            }

            if (featureShape.usaagoFeatureGroupId != null) {
                this.usaagoFeatureGroupId = featureShape.usaagoFeatureGroupId;
            }

            if (featureShape.usaagoImpliedFeatures != null) {
                this.usaagoImpliedFeatures = featureShape.usaagoImpliedFeatures.map((value) => new Feature(value));
            }
        }
    }
}
