// Classes
import { Feature } from "./feature.class";

export class OwnPermission {
    usaagoFeature: Feature = null;
    usaagoIsAllowed = false;

    constructor(ownPermissionShape?: Partial<OwnPermission>) {
        if (ownPermissionShape != null) {
            if (ownPermissionShape.usaagoFeature != null) {
                this.usaagoFeature = new Feature(ownPermissionShape.usaagoFeature);
            }

            if (ownPermissionShape.usaagoIsAllowed != null) {
                this.usaagoIsAllowed = ownPermissionShape.usaagoIsAllowed;
            }
        }
    }
}
