// Classes
import { Group } from "./group.class";
import { Feature } from "./feature.class";

export class GroupPermission {
    usaagoGroup: Group = null;
    usaagoFeature: Feature = null;
    usaagoIsAllowed = false;

    constructor(groupPermissionShape?: Partial<GroupPermission>) {
        if (groupPermissionShape != null) {
            if (groupPermissionShape.usaagoGroup != null) {
                this.usaagoGroup = new Group(groupPermissionShape.usaagoGroup);
            }

            if (groupPermissionShape.usaagoFeature != null) {
                this.usaagoFeature = new Feature(groupPermissionShape.usaagoFeature);
            }

            if (groupPermissionShape.usaagoIsAllowed != null) {
                this.usaagoIsAllowed = groupPermissionShape.usaagoIsAllowed;
            }
        }
    }
}
