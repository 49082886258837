// Framework
import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";
// Classes
import { FeatureGroup } from "../classes/feature-group.class";
// Services
import { FeatureGroupSettingsService } from "../services/feature-group-settings.service";

@Directive({
    selector: "[hasFeaturePermission]"
})
export class HasFeaturePermissionDirective implements OnInit, OnChanges, OnDestroy {
    private subscription = new Subscription();
    private featureKeys: string[] = [];
    /**
     * Array of string with feature keys.
     */
    @Input() hasFeaturePermission: any = null; // * Needs to be any otherwise you can not use the directive without assigning a value
    @Input() hasFeaturePermissionElse: TemplateRef<any> = null;

    constructor(private featureGroupSettingsService: FeatureGroupSettingsService, private view: ViewContainerRef, private template: TemplateRef<any>) { }

    ngOnInit(): void {
        this.setFeatureKeys();

        this.subscription.add(this.featureGroupSettingsService.featureGroupsSubject.asObservable()
            .subscribe(
                (featureGroups) => {
                    this.updateVisibilityForTemplateRef(featureGroups);
                }
            )
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasFeaturePermission.isFirstChange() === false) {
            this.setFeatureKeys();
            this.updateVisibilityForTemplateRef(this.featureGroupSettingsService.loadStorageData().featureGroups);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * This method checks on the following conditions what the visibility of the element reference must be and hides or displays the element:
     * - The parameter is null and thus no permission is present (user probably not logged in).
     * - The input featureKeys is matched with at least one of the feature group permissions.
     * If featureKeys is empty, there is no restriction for the element and it will be displayed when logged in.
     * @param featureGroups Indicator whether the user has at least one FeatureGroup or is otherwise not logged in.
     */
    private updateVisibilityForTemplateRef(featureGroups: FeatureGroup[]): void {
        if (featureGroups != null) {
            if (this.featureKeys.length < 1 || this.featureGroupSettingsService.hasFeaturePermission(this.featureKeys) === true) {
                this.addTemplateRef();
            } else {
                this.addElseTemplateRef();
            }
        } else {
            this.addElseTemplateRef();
        }
    }

    /**
     * This method adds the template reference where the directive selector is set to the view.
     */
    private addTemplateRef(): void {
        this.clearView();
        this.view.createEmbeddedView(this.template);
    }

    /**
     * This method adds the else template reference where the directive selector is set to the view.
     */
    private addElseTemplateRef(): void {
        this.clearView();
        if (this.hasFeaturePermissionElse != null) {
            this.view.createEmbeddedView(this.hasFeaturePermissionElse);
        }
    }

    /**
     * This method removes the template reference where the directive selector is set to the view.
     */
    private clearView(): void {
        this.view.clear();
    }

    /**
     * This method initializes the featureKeys array if the value of hasFeaturePermission is an string array. Otherwise it will set to an empty array.
     */
    private setFeatureKeys(): void {
        if (this.hasFeaturePermission?.length != null && (this.hasFeaturePermission as any[]).every((item) => typeof item === "string") === true) {
            this.featureKeys = this.hasFeaturePermission;
        } else {
            this.featureKeys = [];
        }
    }
}
