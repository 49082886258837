// Enums
import { Gender } from "../enums/gender.enum";

export class BaseUser {
    id: string = null;
    usaagoForename: string = null;
    usaagoSurname: string = null;
    usaagoEmail: string = null;
    usaagoPhone: string = null;
    usaagoGender: Gender = null;
    usaagoProfileImage = "assets/images/profile-image.png";
    usaagoIsPlatformAdmin = false;
    usaagoPosition: number = null;
    usaagoPreferredLanguage: string = null;

    constructor(baseUserShape?: Partial<BaseUser>) {
        if (baseUserShape != null) {
            if (baseUserShape.id != null) {
                this.id = baseUserShape.id;
            }

            if (baseUserShape.usaagoForename != null) {
                this.usaagoForename = baseUserShape.usaagoForename;
            }

            if (baseUserShape.usaagoSurname != null) {
                this.usaagoSurname = baseUserShape.usaagoSurname;
            }

            if (baseUserShape.usaagoEmail != null) {
                this.usaagoEmail = baseUserShape.usaagoEmail;
            }

            if (baseUserShape.usaagoPhone != null) {
                this.usaagoPhone = baseUserShape.usaagoPhone;
            }

            if (baseUserShape.usaagoGender != null) {
                this.usaagoGender = baseUserShape.usaagoGender;
            }

            if (baseUserShape.usaagoProfileImage != null) {
                this.usaagoProfileImage = baseUserShape.usaagoProfileImage;
            }

            if (baseUserShape.usaagoIsPlatformAdmin != null) {
                this.usaagoIsPlatformAdmin = baseUserShape.usaagoIsPlatformAdmin;
            }

            if (baseUserShape.usaagoPosition != null) {
                this.usaagoPosition = baseUserShape.usaagoPosition;
            }

            if (baseUserShape.usaagoPreferredLanguage != null) {
                this.usaagoPreferredLanguage = baseUserShape.usaagoPreferredLanguage;
            }
        }
    }

    toJSON(): BaseUser {
        const tmpUser = {
            ...this
        };
        tmpUser.usaagoProfileImage = null;
        return tmpUser;
    }
}
