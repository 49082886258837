// Framework
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Enums
import { AddressType } from "../enums/address-type.enum";

@Pipe({
    name: "addressType",
})
export class AddressTypePipe implements PipeTransform {
    constructor(private translate: TranslateService) { }

    transform(value: AddressType): string {
        switch (value) {
            case AddressType.billing:
                return this.translate.instant("address-type-pipe.billing");
            case AddressType.shipping:
                return this.translate.instant("address-type-pipe.shipping");
            case AddressType.billingShipping:
                return this.translate.instant("address-type-pipe.billing-shipping");
            case AddressType.none:
                return this.translate.instant("address-type-pipe.none");
            default:
                return null;
        }
    }
}
