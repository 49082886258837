// Framework
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Plugins
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxPaginationModule } from "ngx-pagination";
// Project modules
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { ShareRoutingModule } from "./share-routing.module";
// Components
import { ShareComponent } from "./components/share/share.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
// Directives
import { HasFeaturePermissionDirective } from "./directives/has-feature-permission.directive";
import { InputLengthDirective } from "./directives/input-length.directive";
// Pipes
import { GenderPipe } from "./pipes/gender.pipe";
import { OrganizationTypePipe } from "./pipes/organization-type.pipe";
import { ImageTypePipe } from "./pipes/image-type.pipe";
import { OrderStatusPipe } from "./pipes/order-status.pipe";
import { TransactionStatusPipe } from "./pipes/transaction-status.pipe";
import { AddressTypePipe } from "./pipes/address-type.pipe";

@NgModule({
    declarations: [
        ShareComponent,
        NotFoundComponent,
        GenderPipe,
        HasFeaturePermissionDirective,
        InputLengthDirective,
        OrganizationTypePipe,
        ImageTypePipe,
        ConfirmModalComponent,
        OrderStatusPipe,
        TransactionStatusPipe,
        AddressTypePipe,
    ],
    imports: [
        CommonModule,
        ShareRoutingModule,
        TranslateModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            positionClass: "toast-bottom-right",
            maxOpened: 5,
            toastClass: "",
            iconClasses: {
                success: "alert alert--success",
                error: "alert alert--danger",
                info: "alert alert--info",
                warning: "alert alert--warning"
            },
            // individual
            closeButton: false,
            tapToDismiss: true,
            progressBar: true,
        }),
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
    ],
    exports: [
        HttpClientModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        GenderPipe,
        NgMultiSelectDropDownModule,
        NgxPaginationModule,
        HasFeaturePermissionDirective,
        InputLengthDirective,
        OrganizationTypePipe,
        ImageTypePipe,
        ConfirmModalComponent,
        OrderStatusPipe,
        TransactionStatusPipe,
        AddressTypePipe,
    ]
})
export class ShareModule { }
