// Framework
import { Directive, ElementRef, forwardRef, HostListener, OnInit, Renderer2 } from "@angular/core";
import { AbstractControl, ControlContainer, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
    selector: "input[appInputLength], textarea[appInputLength]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputLengthDirective),
            multi: true
        }
    ]
})
export class InputLengthDirective implements OnInit, ControlValueAccessor {
    private maxLength: string = null;
    private groupDiv = document.createElement("div");
    private countSpan = document.createElement("small");
    private formControl: AbstractControl = null;

    @HostListener("input", ["$event.target.value"]) onInput = (_: any) => { };
    @HostListener("blur") onTouched = () => { };

    constructor(private el: ElementRef, private renderer: Renderer2, private controlContainer: ControlContainer) { }

    ngOnInit(): void {
        this.maxLength = (this.el.nativeElement as HTMLInputElement).getAttribute("maxLength");
        this.formControl = (this.controlContainer.control as FormGroup).get((this.el.nativeElement as HTMLInputElement).getAttribute("formControlName"));
        (this.el.nativeElement as HTMLInputElement).before(this.groupDiv);
        this.groupDiv.append(this.countSpan, this.el.nativeElement);
        this.groupDiv.setAttribute("class", "max-length__form-group");
        this.countSpan.setAttribute("class", "max-length__input-counter");
    }

    writeValue(currentInput: string): void {
        if (currentInput == null) {
            this.changeCharCount("");
        } else {
            this.changeCharCount(currentInput);
        }
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onInput = (currentInput: string) => {
            fn(this.changeCharCount(currentInput));
            this.formControl.patchValue(currentInput);
        };
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private changeCharCount(currentInput: string): void {
        this.renderer.setProperty(
            this.countSpan,
            "innerHTML",
            currentInput.length + "/" + this.maxLength
        );
        this.renderer.setProperty(
            this.el.nativeElement,
            "value",
            currentInput
        );
    }
}
