// Framework
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, iif, of, Subscription } from "rxjs";
import { distinctUntilChanged, mergeMap } from "rxjs/operators";
// Classes
import { FeatureGroup } from "../classes/feature-group.class";
// Services
import { BaseLocalStorageService } from "./templates/base-local-storage.service";
import { FeatureGroupApiService } from "./api/feature-group-api.service";
import { AppSettingsService } from "./app-settings.service";

interface AppSettingsData {
    featureGroups: FeatureGroup[];
    storageVersion: string;
}

@Injectable({
    providedIn: "root"
})
export class FeatureGroupSettingsService extends BaseLocalStorageService<AppSettingsData> implements OnDestroy {
    private subscriptions = new Subscription();
    featureGroupsSubject = new BehaviorSubject<FeatureGroup[]>([]);

    constructor(private featureGroupApi: FeatureGroupApiService, private appSettingsService: AppSettingsService) {
        super("usaago-frontend-feature-group-settings", "1.0.0");

        this.subscriptions.add(this.appSettingsService.getIsLoggedInAsObservable()
            .pipe(
                mergeMap((isLoggedIn) => {
                    return iif(() => isLoggedIn === true, this.featureGroupApi.getOwnFeatureGroups(), of(null));
                }),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            )
            .subscribe(
                (featureGroups: FeatureGroup[]) => {
                    if (featureGroups != null) {
                        this.saveOwnFeatureGroups(featureGroups);
                    }

                    this.featureGroupsSubject.next(featureGroups);
                },
                (err) => console.error(err)
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * This method saves the given feature groups in the cookie.
     */
    saveOwnFeatureGroups(featureGroups: FeatureGroup[]): void {
        const appSettingsData = this.loadStorageData();
        appSettingsData.featureGroups = featureGroups;
        this.saveStorageData(appSettingsData);
    }

    /**
     * This method checks if the passed parameter featureKey is contained in the array of featureGroups.
     * @param featureKey Search key to check permission for a feature.
     * @returns The result if there is a permission for the featureKey.
     */
    hasFeaturePermission(featureKeys: string[]): boolean {
        return this.loadStorageData().featureGroups.some((featureGroup) => {
            return featureGroup.usaagoOwnPermissions.some((ownPermission) => {
                return featureKeys.some((featureKey) => featureKey === ownPermission.usaagoFeature.usaagoKey);
            });
        });
    }

    /**
     * This method returns a default initialized AppSettingsData object.
     * @returns default initialized AppSettingsData object
     */
    protected getDefaultStorageData(): AppSettingsData {
        return {
            featureGroups: [],
            storageVersion: this.storageVersion,
        };
    }
}
